
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import NewFeatureReferenceModel from '../../models/Keystone_v2/NewFeatureReferenceModel'
import { InstructionDsp } from '../../../types/instruction_type'

// Use this component when using a feature used in NewFeatureReference.
// This will provide an easy way to search for it when it is not in alpha / beta / released_with_exception states
// The best trick, if possible, is to write to create a component with the new feature that will be inside the body of this component
// then we will just remove the encapsulation and the feature would (""would"") be ready to go
@Component({})
export default class NewFeatureReferenceEncapsulation extends Vue {
  @Prop({ required: true }) nfrDevName: string // The DEV_NAME of the new feature reference
  @Prop({ required: true }) application: string // garnularity -> at what level is the new feature reference (ex: member)
  @Prop({ required: true }) entityId: number // ID of the current entity (ex: keystone_settings_member.id) to check if the new feature is active or not
  @Prop({ required: true }) dsp: InstructionDsp // Self explanatory
  @Prop({ required: false, default: () => true }) specialCondition: boolean // Need something special like "all dsp but one"? There you go

  newFeatureReference: NewFeatureReferenceModel = null

  mounted () {
    this.loadNewFeatureReferenceData()
  }

  /**
   * try to get the newFeatureReference in the Vuex store.
   * If we can't find it, we call the api for this devName
   */
  async loadNewFeatureReferenceData () {
    if (this.nfrInStore) {
      console.warn('Load nfr in store')
      this.newFeatureReference = this.nfrInStore
    } else {
      console.warn('Load nfr from api')
      const response = await this.$apiCaller.getNewFeatureReferenceUsageForEntity(this.nfrDevName, this.application, this.entityId)
      if (this.$apiCaller.isResponseError(response)) {
        const error = this.$commonUtils.getErrorInResponse(response)
        const message = `Error when calling getNewFeatureReferenceUsageForEntity. Error: ${error}`
        console.warn(message)
      } else {
        this.newFeatureReference = new NewFeatureReferenceModel(response.data)
      }
    }
  }

  get nfrInStore (): NewFeatureReferenceModel | null {
    const newFeatureReferences: NewFeatureReferenceModel[] = this.$store.getters.getCurrentNewFeatureReferences
    return newFeatureReferences.find(item => item.devName === this.nfrDevName)
  }

  get someArgsAreMissing () {
    return !this.entityId || !this.dsp
  }

  // Different cases
  // it's incompatible on this dsp? -> Display the old feature
  // -> it's in alpha / beta on the current dsp and activated on the member? -> Display the new feature
  // -> it's in released_with_exception (new by default but you can disable it)? -> if there's a line in legacyFeatureRef, display the old one
  // -> it's in released? -> why are you here?
  get isNewFeatureOn (): boolean {
    if (this.someArgsAreMissing) {
      return false
    }
    return this.newFeatureReference.isFeatureOn(this.dsp)
  }
}
