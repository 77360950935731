<template>
  <v-row class="wrap justify-start">
    <v-col cols="auto" class="mr-4 shrink">
      {{title}}
    </v-col>
    <v-col>
      <v-menu >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">info</v-icon>
        </template>
        <div class="font-form syntax-rules">
            <div>Rules : </div>
            <ul>
              <li v-for="(rule, index) in rules" :key="index">
                {{ rule }}
              </li>
            </ul>
            <div> Ex : </div>
            <div v-for="(example, index) in examples" :key="index">
                {{ example }}
            </div>
        </div>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MatchingTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {

  },
  data: function () {
    return {
      rules: [
        'Order has to be "li_id to copy data from" : "li_id to match in the current IO"',
        'One pair of id per line.',
        'Each id must be separated by a ":"',
        'Spaces are allowed between ids and points'
      ],
      examples: [
        '7897789:56757657',
        '7878 : 908987',
        '898967 : 454568'
      ]
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
</style>
