import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export class constraintsFormPropsMixins extends Vue {
  @Prop({ required: true }) model: any
  @Prop({ required: true }) label: string
  @Prop({ required: true }) computed: string
  @Prop({ required: true }) outline: boolean
  @Prop({ required: true }) isPercentage: boolean
  @Prop({ required: true }) hint: string
  @Prop({ required: true }) isDouble: boolean
  @Prop({ required: true }) disabled: boolean
  @Prop({ required: false, default: false }) disabledNoConstraints: boolean
  @Prop({ required: false, default: false }) requiredChoice: boolean
  @Prop({ required: true }) kpiToOptimizeValue: any
  @Prop({ required: true }) doubleModel: any
  @Prop({ required: true }) doubleComputed: any
  @Prop({ required: true }) untieValue: any
  @Prop({ required: true }) className: string
  @Prop({ required: true }) currentKpiToOptimize: string
  @Prop({ required: true }) isDataForm: boolean
  @Prop({ required: false }) alertsTips: {content: string, type?: 'error' | 'info'}
  @Prop({ required: false }) errorMessages: string[]
}
