
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import NewFeatureReferenceEncapsulation
  from '@/components/NewFeatureReferenceEncapsulation/NewFeatureReferenceEncapsulation.vue'
import ConstraintsForm from '@/components/TableComponents/Form/FormFragments/ConstraintsForm.vue'
import { constraintsFormPropsMixins } from '@/mixins/constraintsFormPropsMixins'

@Component({
  components: {
    ConstraintsForm,
    NewFeatureReferenceEncapsulation
  }
})
export default class ConstraintsFormNewFeatureReferenceWrapper extends mixins(constraintsFormPropsMixins) {
  @Prop({ required: false, default: null })
  featureRefConfig: FeatureRefConfig

  updateValueItem (computed: string, value: number | string, noConstraints: boolean) {
    this.$emit('updateConstraints', computed, value, noConstraints)
  }
}
