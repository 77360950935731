<template>
  <v-slide-y-transition>
      <v-row v-if="showConversionFunnel" wrap class="pa-4">
          <v-col cols="12" class="py-0">
            <v-text-field
              :error-messages="errorMessages()"
              class="invisible-input-slot-error-message error-color"
              tab="tab1"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-text-field
              class="invisible-input-slot-error-message hint-color"
              tab="tab1"
              :hint="getHintConversionFunnel()"
              :persistent-hint="getHintConversionFunnel() !== ''"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div @click="nbFunnelId = 'add'" class="cursor-pointer">
              <v-btn small fab color="#3379C7" class="mr-2"><v-icon color="white">add</v-icon></v-btn>
              <span>Add new step</span>
            </div>
          </v-col>

          <v-col cols="12" v-for="n in nbFunnelId" :key="n">
            <v-row wrap>
              <v-col cols="3" class="number-pixel-id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ma-1"
                      v-on="on"
                      small
                      fab
                      color="#3379C7"
                      @click="removeFunnel(n)"
                    >
                      <v-icon color="white">remove</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Remove Step
                  </span>
                </v-tooltip>
                {{n}} :
                <v-chip
                  v-for="(pixel, index) in conversionFunnelPixelId[n]"
                  :key="pixel + '_' + editedItemId + '_' + index + '_' + n"
                  @click:close="removePixel(n,index)"
                  small
                  close
                  class="pixel-chip pl-0 my-1"
                  style="min-width: max-content"
                >
                  <v-avatar color="#2E6DB3" class="mr-2">
                    <span style="color: white">{{pixel.origin === '3rd_party' ? '3rd' : 'DSP'}}</span>
                  </v-avatar>

                  ({{pixel.type}})

                  {{pixel.id}}
                </v-chip>
              </v-col>

              <v-col cols="2" class="pa-0">
                <v-select
                  v-model.trim="pixelContainer[n].origin"
                  tab="tab1"
                  :items="originItemsByDsp"
                  @change="setTypeToPixelIfThirdParty(n, $event)"
                >

                </v-select>
              </v-col>

              <v-col cols="3" class="pa-0">
                <v-select
                  v-model.trim="pixelContainer[n].type"
                  tab="tab1"
                  :items="typeItems"
                  :disabled="disabledOrigin(pixelContainer[n].origin)"
                >

                </v-select>
              </v-col>

              <v-col cols="2" class="pa-0">
                <v-text-field
                  v-model.trim="pixelContainer[n].id"
                  tab="tab1"
                  :hint="pixelCantBeEmpty(n)"
                  persistent-hint
                  :error-messages="[...funnelCantBeEmpty(n), ...funnelMustBeRightType(n)]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom :disabled="!pixelContainer[n].id">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :disabled="!pixelContainer[n].id || !isPixelContainerRightType(n)" fab small @click="addPixelIdToItem(n)"><v-icon>add</v-icon></v-btn>
                  </template>
                  <span>
                    Add Pixel
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
    </v-row>
  </v-slide-y-transition>
</template>

<script>
import FunnelIdModel, {
  THIRD_PARTY,
  SEGMENT,
  PIXEL,
  DSP
} from '../../../../models/Overlay/FunnelIdModel'
import * as conversionFunnelHelper from '../../../../models/Helpers/conversionFunnelHelper'
import {
  checkPreMepValidatorToErrorMessage, isCheckPreMepValidatorWarning
} from '../../../../../utils/CheckPreMep/CheckPremepValidatorUtils'
import { checkPremepValidatorMixins } from '@/mixins/checkPremepValidatorMixins'
export default {
  name: 'ConversionFunnel',
  props: {
    value: {
      type: Object
    },
    kpiToOptimize: {
      type: String
    },
    editedItemId: {
      type: String
    }
  },
  components: {},
  mixins: [checkPremepValidatorMixins],
  data: function () {
    return {
      pixelContainer: {},
      conversionFunnelPixelId: {},
      cachedFunnel: {},
      originItems: [
        { text: '3rd', value: THIRD_PARTY },
        { text: 'DSP', value: DSP }
      ],
      typeItems: [
        { text: 'Segment', value: SEGMENT },
        { text: 'Pixel', value: PIXEL }
        // { text: 'Viewability', value: VIEWABILITY },
        // { text: 'Click', value: CLICK },
        // { text: 'Completion', value: COMPLETION }
      ]
    }
  },
  created: function () {

  },
  mounted: function () {
    this.generatePixelComponent()
    this.conversionFunnelPixelId = this.value
  },
  methods: {
    /**
     * delete a element of funnel
     * @param n : {Number} the index of the element to delete
     */
    removeFunnel (n) {
      let baseLength = Object.keys(this.conversionFunnelPixelId).length

      this.$delete(this.conversionFunnelPixelId, String(n))

      for (let i = n; i <= baseLength; i++) {
        this.$set(this.conversionFunnelPixelId, String(i), this.conversionFunnelPixelId[String(i + 1)])
      }

      this.$delete(this.conversionFunnelPixelId, baseLength)
    },
    /**
     * @param n : {Number}
     * @param pixelIndex : {String} the pixel index
     */
    removePixel (n, pixelIndex) {
      let pixelListTemp = Array.from(this.conversionFunnelPixelId[n.toString()])
      pixelListTemp.splice(pixelIndex, 1)
      this.$set(this.conversionFunnelPixelId, n.toString(), pixelListTemp)
    },
    pixelCantBeEmpty (n) {
      // hint cause a bad display when facebook, and it is not mandatory to display it (just comfort)
      if (this.$route.params.dsp === this.$FACEBOOK) {
        return
      }
      if (!this.$commonUtils.issetInObject(this.pixelContainer[n], 'id') || this.pixelContainer[n].id.trim() === '') {
        return 'Enter a pixel id to add it to the funnel'
      }
    },
    funnelCantBeEmpty (n) {
      return Object.keys(this.conversionFunnelPixelId[n]).length === 0
        ? ['Funnel can\'t be empty']
        : []
    },
    funnelMustBeRightType (n) {
      return !this.isPixelContainerRightType(n)
        ? ['DSP pixels can only be integers.']
        : []
    },
    isPixelContainerRightType (n) {
      return this.isPixelRightType(this.pixelContainer[n])
    },
    isPixelRightType (pixel) {
      const dsp = this.$route.params.dsp
      if ([this.$APPNEXUS, this.$DBM, this.$MEDIAMATH, this.$BEESWAX].indexOf(dsp) === -1) {
        return true
      }
      return !pixel.id || !(pixel.origin === 'dsp' && isNaN(pixel.id))
    },
    errorMessages () {
      let errors = []
      if (!this.conversionFunnelPixelId || Object.keys(this.conversionFunnelPixelId).length <= 0 || this.conversionFunnelPixelId['1'].length <= 0) {
        errors.push('Conversion funnel can\'t be empty')
      }

      if (!this.isConversionFunnelRightType()) {
        errors.push('Your conversion funnel is not right. DSP pixels can only be integers. Please remove or replace these DSP pixels')
      }

      if (!this.isWarningCPMValidator()) {
        const errorCPMValidator = this.getErrorRelatedToFieldCPMValidator(['conversion_funnel_pixel_ids'])
        if (errorCPMValidator.length) {
          errors = [...errors, errorCPMValidator.join(' ')]
        }
      }

      return errors
    },
    getHintConversionFunnel () {
      if (this.isWarningCPMValidator()) {
        const errorCPMValidator = this.getWarningRelatedToFieldCPMValidator(['conversion_funnel_pixel_ids'])
        return errorCPMValidator.join(' ')
      }
      return ''
    },
    /**
     * @param n : {Number} n
     */
    addPixelIdToItem (n) {
      let pixelToAdd = this.pixelContainer[n]
      // eslint-disable-next-line eqeqeq
      if (pixelToAdd != 0 && pixelToAdd !== null && pixelToAdd !== undefined) {
        let pixelListTemp = Array.from(this.conversionFunnelPixelId[n.toString()])
        pixelListTemp.push(pixelToAdd)
        this.$set(this.conversionFunnelPixelId, n.toString(), pixelListTemp)
      }
      // clean pixel container
      this.$set(this.pixelContainer, String(n), new FunnelIdModel(this.kpiToOptimize))
    },
    setCachedFunnel () {
      this.cachedFunnel = this.$commonUtils.deepCopy(this.conversionFunnelPixelId)
    },
    resetCachedFunnel () {
      this.cachedFunnel = {}
      this.conversionFunnelPixelId = {}
    },
    resetPixelComponent () {
      this.generatePixelComponent()
    },
    generatePixelComponent () {
      for (let i in this.$commonUtils.rangeNum(20)) {
        this.$set(this.pixelContainer, i, new FunnelIdModel(this.kpiToOptimize))
      }
    },
    resetAll () {
      this.resetCachedFunnel()
      this.resetPixelComponent()
    },
    setTypeToPixelIfThirdParty (pixelContainerIndex, originValue) {
      if (conversionFunnelHelper.isKpiHaveConversionFunnelId(this.kpiToOptimize)) {
        if (originValue === THIRD_PARTY) {
          this.pixelContainer[pixelContainerIndex].type = PIXEL
        }
      }
    },
    disabledOrigin (originValue) {
      return originValue === THIRD_PARTY || this.$route.params.dsp !== this.$APPNEXUS
    },
    setConversionFunnelValuePerKpi () {
      const defaultValue = conversionFunnelHelper.getConversionFunnelDefaultValuePerKpi(this.kpiToOptimize)
      this.$set(this, 'conversionFunnelPixelId', defaultValue)
    },
    isConversionFunnelRightType () {
      for (let key in this.conversionFunnelPixelId) {
        let pixelArray = this.conversionFunnelPixelId[key]

        if (Array.isArray(pixelArray)) {
          for (let i in pixelArray) {
            if (!this.isPixelRightType(pixelArray[i])) {
              return false
            }
          }
        }
      }
      return true
    }
  },
  computed: {
    /**
     * true_conversion_funnel_pixel_ids
     */
    nbFunnelId: {
      get: function () {
        return this.conversionFunnelPixelId !== '' && this.conversionFunnelPixelId !== undefined && this.conversionFunnelPixelId !== null
          ? Object.keys(this.conversionFunnelPixelId).length
          : 0
      },
      set: function (action) {
        if (this.conversionFunnelPixelId === undefined || this.conversionFunnelPixelId === null) {
          this.$set(this, 'conversionFunnelPixelId', {})
        }
        let currentLength = Object.keys(this.conversionFunnelPixelId).length
        let newLength
        if (action === 'add') {
          newLength = currentLength + 1
          this.$set(this.conversionFunnelPixelId, newLength.toString(), [])
        } else if (action === 'remove') {
          this.$delete(this.conversionFunnelPixelId, currentLength)
        }
      }
    },
    showConversionFunnel () {
      return ['CPA', 'CPA_PC'].indexOf(this.kpiToOptimize) !== -1
    },
    originItemsByDsp () {
      if (this.$route.params.dsp === this.$FACEBOOK) {
        return this.originItems.filter(item => item.text !== '3rd')
      }
      return this.originItems
    }
  },
  watch: {
    'conversionFunnelPixelId': {
      immediate: true,
      deep: true,
      handler: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    'kpiToOptimize': {
      immediate: false,
      deep: true,
      handler: function (kpiToOptimize, previousKpiToOptimize) {
        this.$nextTick(function () {
          if (conversionFunnelHelper.isKpiHaveConversionFunnelId(this.kpiToOptimize)) {
            if (conversionFunnelHelper.isConversionFunnelWithoutId(this.conversionFunnelPixelId)) {
              if (!this.$commonUtils.objectIsEmpty(this.cachedFunnel)) {
                this.$set(this, 'conversionFunnelPixelId', this.cachedFunnel)
              } else {
                this.$set(this, 'conversionFunnelPixelId', {})
              }
            }
            if (this.nbFunnelId < 1) {
              this.nbFunnelId = 'add'
            }
          } else {
            this.setConversionFunnelValuePerKpi()
          }
        })
      }
    },
    value (val) {
      if (val !== this.conversionFunnelPixelId) {
        this.conversionFunnelPixelId = val
      }
    }
  }
}
</script>

<style>
  .pixel-chip span.v-chip__content {
    font-size: 9px;
    z-index: 1;
  }

  .number-pixel-id {
    padding-top: 1em;
  }
</style>
