<template>
  <div style="width: 100%;" class="mt-2">
    <v-alert
      :value="errorMessages.length > 0"
      type="error"
      :icon="'warning'"
    >
      <span v-html="errorMessages.join('<br>')"></span>
    </v-alert>
    <!-- NOT DISPLAYED INPUT, FOR COUNTING ERROR MESSAGE AND THE VALIDATION SYSTEM -->
    <div style="display: none;">
      <v-text-field
        :error-messages="errorMessages"
        class="invisible-input-slot-error-message"
        :tab="tab"
        :error-count="4"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertErrorBox',
  props: {
    errorMessages: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    tab: {
      type: String,
      required: false,
      default: 'tab1'
    }
  }
}
</script>

<style scoped>

</style>
