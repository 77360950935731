import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
import MemberModel from '@/models/Keystone_v2/MemberModel'

class MemberSettingModel extends BaseKeystoneEntity {
  constructor (data = {}) {
    super(data)
    this.id = data.id
    this.forceDlClient = data.force_dl_client
    this.member = data.member ? new MemberModel(data.member) : new MemberModel()
    this.prodAuthorizeExternalSegments = Boolean(data.prod_authorize_external_segments)
    this.siegeIdGroup = data.siege_id_group
    this.prodHasSegmentFeeAvailable = Boolean(data.prod_has_segment_fee_available)
    this.saveOriginal()
  }
}

export default MemberSettingModel
