
import { mixins } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { EDIT } from '../../../../../store'
import * as commonUtils from '../../../../../utils/commonUtils'
import { rulesMixin } from '../../../../mixins/rulesMixin'
import { constraintsFormPropsMixins } from '@/mixins/constraintsFormPropsMixins'
// TODO too complex system for double value. need to be changed

@Component({})
export default class ConstraintsForm extends mixins(rulesMixin, constraintsFormPropsMixins) {
  // counters
  percentageCounterNb: number = 5
  numberCounterNb: number = 7

  noConstraints: boolean = false
  value: any = null
  doubleValue: any = null
  cachedValue: any = null

  mounted () {
    if (this.computed === 'proxyTargetKpiValue' || this.requiredChoice) {
      this.noConstraints = false
    }
    this.value = this.convertValueToPercentIfNeeded()
    this.doubleValue = this.doubleModel
  }

  emitUpdate () {
    let valueToSend = this.isPercentage && this.value !== 0 && this.value !== '' && this.value !== undefined && this.value !== null
      ? (this.value / 100)
      : this.value
    this.$emit('updateConstraints', this.computed, valueToSend, this.noConstraints)
  }

  checkNoConstraint (): Array<string> {
    let errors = []

    if (!this.noConstraints && (this.value === undefined || this.value === null || this.value === '' || this.value === 0)) {
      errors.push('You must check no constraint if you don\'t want to set ' + this.label)
    }

    return errors
  }

  checkNoConstraintDouble (): Array<string> {
    let errors = []

    if (!this.noConstraints && (this.doubleValue === undefined || this.doubleValue === null || this.doubleValue === '' || this.doubleValue === 0)) {
      errors.push('You must check no constraint if you don\'t want to set ' + this.label)
    }

    return errors
  }

  errorMessageExpectedMarginDailyWithRevenueType (): Array<string> {
    let errors = []
    if (this.requiredChoice && (this.value == null || this.value === '' || this.value === 0)) {
      errors.push(`You have to fill ${this.label} if you want to use Rev CPM revenue type.`)
    }
    return errors
  }

  convertValueToPercentIfNeeded () {
    return this.isPercentage && this.model !== '' && this.model !== 0 && this.model !== undefined && this.model !== null
      ? commonUtils.roundPercentage(this.model)
      : this.model
  }

  resetConstraint () {
    this.noConstraints = false
  }

  /**
   * check the constraint if needed (if the form is edit or inDataForm )
   */
  setConstraintToTrueIfEditAndValueNotSet () {
    let formStatus = this.$store.getters.getFormStatus
    /**
     * @type {BriefModel}
     */
    let brief = this.$store.getters.getCurrentTraderBrief
    // BUSINESS LOGIC : when isDataForm, we do want the no constraints whecked, except when maxCPCV or maxCPC are set in brief and highestAllowedCPM in instructions not
    const isMaxCPCorMaxCPCVinBrief = this.computed === 'highestAllowedCPM' && brief && (brief.maxCPCV || brief.maxCPC)
    const isNoConstraintsCheckNeeded = formStatus === EDIT ||
      (this.isDataForm && !isMaxCPCorMaxCPCVinBrief && (brief == null || !brief.isNoteBrief()))
    if (isNoConstraintsCheckNeeded) {
      if ((this.value === null || this.value === '' || this.value === undefined)) {
        this.noConstraints = !this.requiredChoice
      }
    }
  }

  changeTargeted () {
    this.$emit('updateConstraints', this.doubleComputed, this.doubleValue, this.noConstraints)
  }

  doubleCantBeEmpty () {
    if (this.doubleValue === '' || this.doubleValue === null || this.doubleValue === undefined) {
      return ['This value can\'t be empty']
    }
  }

  inferiorToHundred () {
    if (this.value > 100) {
      return ['Percentage value must be inferior to 100']
    }

    return []
  }

  setNumberCounterNb () {
    if (this.currentKpiToOptimize !== 'reach' && this.currentKpiToOptimize !== 'reach_and_frequency') {
      return this.numberCounterNb
    } else {
      return false
    }
  }

  // GETTERS
  /**
   * create a array of object with kpiToOptimizeValue by adding 'Targeted' to the text property
   * @returns {Array}
   */
  get addTargetedToItems () {
    return this.kpiToOptimizeValue.reduce((acc: Array<any>, item: any) => {
      acc.push({
        text: `Targeted ${item}`,
        value: item
      })
      return acc
    }, [])
  }

  // WATCHERS
  @Watch('model', { immediate: true, deep: true })
  onModelChange (model: any) {
    this.value = this.convertValueToPercentIfNeeded()
  }

  @Watch('doubleModel', { immediate: true, deep: true })
  onDoubleModelChange (doubleModel: any) {
    this.doubleValue = doubleModel
  }

  @Watch('noConstraints', { immediate: true, deep: true })
  onNoConstraintsChange (noConstraints: boolean) {
    this.$emit('no-constraints-updated')
    if (this.noConstraints) {
      this.cachedValue = this.value
      this.value = ''
      this.doubleValue = ''
      this.changeTargeted()
      this.emitUpdate()
    } else {
      if (this.cachedValue !== null && this.cachedValue !== '' && this.computed !== 'proxyTargetKpiValue') {
        this.value = this.cachedValue
        this.emitUpdate()
      }

      if (this.computed === 'proxyTargetKpiValue' &&
          this.kpiToOptimizeValue.indexOf(this.currentKpiToOptimize) !== -1 && this.doubleValue === '') {
        this.doubleValue = this.currentKpiToOptimize
        this.changeTargeted()
      }
    }
  }

  @Watch('$store.getters.getFormStatus', { immediate: true, deep: true })
  onGetFormStatusChange (formStatus: any) {
    this.$nextTick(function () {
      this.setConstraintToTrueIfEditAndValueNotSet()
    })
  }

  @Watch('isPercentage')
  onIsPercentageChange () {
    this.value = this.convertValueToPercentIfNeeded()
  }

  @Watch('untieValue', { deep: true })
  onUntieValueChange (untie: any) {
    if (this.computed === 'proxyTargetKpiValue') {
      this.noConstraints = false
    }
  }
}
