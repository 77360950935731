import NewFeatureReferenceModel from '../src/models/Keystone_v2/NewFeatureReferenceModel'
import { checkPreMep } from './featureReferenceConstant'
import { InstructionDsp } from '../types/instruction_type'

/**
 * @param newFeaturesRef The new feature references of the member
 * @param dsp
 */
export function isActivatedCheckPreMep (newFeaturesRef: NewFeatureReferenceModel[], dsp: InstructionDsp) {
  const featureInfo = newFeaturesRef.find(item => item.devName === checkPreMep)

  // if no trace of the feature in nfr, the feature is disabled cause incompatible feature for the dsp
  // are not returned to the query
  if (!featureInfo) {
    // may be : query the new_ref_line (with no entity_id param) to be sur than the line
    // is really incompatible with the dsp ? (and not just "here")
    return false
  }

  return featureInfo.isFeatureOn(dsp)
}
