<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">Tool kit</v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, i) in multiActionToolsByDsp" :key="i" @click="emitChangeAllInstructions(item.value, item.action)">
        <v-list-item-title>
          <v-icon :class="item.class">{{item.icon}}</v-icon>
          {{ 'Set all ' + item.value + ' to ' + item.action.toString() }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'MultiActionTools',
  data: () => {
    return {
      multiActionTools: [
        { value: 'is_active', action: true, icon: 'lens', class: 'green-lens' },
        { value: 'is_active', action: false, icon: 'lens', class: 'red-lens' },
        { value: 'retry', action: true, icon: 'refresh', class: 'item-is-on refresh' },
        { value: 'retry', action: false, icon: 'refresh', class: 'item-is-off' },
        { value: 'fast_retry', action: true, icon: 'flash_on', class: 'item-is-on flash' },
        { value: 'fast_retry', action: false, icon: 'flash_on', class: 'item-is-off' }
      ]
    }
  },
  methods: {
    emitChangeAllInstructions (field, action) {
      this.$emit('change-all-instructions', field, action)
    }
  },
  computed: {
    multiActionToolsByDsp () {
      if (this.$route.params.dsp === this.$YOUTUBE) {
        return this.multiActionTools.filter(item => item.value !== 'retry')
      }
      return this.multiActionTools
    }
  }
}
</script>

<style scoped>

</style>
