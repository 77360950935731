<template>
  <v-text-field
    class="no-spinners"
    :mask="digitMask[counter]"
    v-model="percentValue"
    :label="label"
    :disabled="disabled"
    :suffix="'%'"
    :rules="addedRules.length ? addedRules : [rules.numeric]"
    :counter="counter"
    :error-messages="errorMessage"
    :tab="tab"
    :hint="hint"
  >
  </v-text-field>
</template>

<script>
import { rulesMixin } from '../../../../mixins/rulesMixin'

export default {
  name: 'PercentageTextField',
  props: {
    value: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    errorMessage: {
      type: Array,
      default: () => {
        return []
      }
    },
    tab: {
      type: String,
      default: 'tab1'
    },
    counter: {
      type: Number,
      default: 3
    },
    addedRules: {
      type: Array,
      default: () => {
        return []
      }
    },
    hint: {
      type: String,
      default: null
    }
  },
  mixins: [rulesMixin],
  data: function () {
    return {
      percentValue: null
    }
  },
  created () {},
  mounted () {
    this.percentValue = this.value ? this.$roundPercentage(this.value) : this.value
  },
  methods: {

  },
  computed: {
  },
  watch: {
    'percentValue': {
      immediate: false,
      deep: true,
      handler: function (newValue) {
        newValue = newValue ? (newValue / 100) : newValue
        this.$emit('input', newValue)
      }
    },
    value (val) {
      if (val !== this.percentValue) {
        this.percentValue = val ? this.$roundPercentage(val) : val
      }
    }
  }
}
</script>

<style scoped>

</style>
