import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { DspInstruction, IoBrief } from '../../types/instruction_type'

@Component
export class instructionsMixins extends Vue {
  @Prop({}) editedItem: DspInstruction

  isCuiCuiMember (clientId: number | string): boolean {
    const cuicuiMember = this.cuicuiMember()
    return cuicuiMember && clientId && this.$route.params.dsp in cuicuiMember &&
      cuicuiMember[this.$route.params.dsp].indexOf(clientId.toString()) !== -1
  }

  isFastTrackCuiCuiMember (clientId: number | string): boolean {
    const fastTrack = this.fastTrackCuiCuiMember()
    return fastTrack && clientId && this.$route.params.dsp in fastTrack &&
      fastTrack[this.$route.params.dsp].indexOf(clientId.toString()) !== -1
  }

  cuicuiMember (): {[key: string]: Array<string | number>} {
    return this.$surcoucheConfig.get('CUICUI_MEMBER')
  }

  fastTrackCuiCuiMember (): {[key: string]: Array<string | number>} {
    return this.$surcoucheConfig.get('FAST_TRACK_CUICUI_MEMBER')
  }
}

@Component
export class ioBriefMixins extends instructionsMixins {
  @Prop() declare editedItem: IoBrief<DspInstruction>
}
