<template>
  <div v-if="hasWarning" class="warning-message-constraints">
    {{messageWarning}}
  </div>
</template>

<script>
export default {
  name: 'WarningMessageBox',
  props: {
    hasWarning: {
      type: Boolean
    },
    messageWarning: {
      type: String
    }
  },
  data: function () {
    return {}
  }
}
</script>

<style scoped>
.warning-message-constraints {
  color: orange;
  font-size: 0.9em;
  line-height: 1;
}
</style>
