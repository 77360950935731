import { render, staticRenderFns } from "./FormTitle.vue?vue&type=template&id=288802e2&scoped=true"
import script from "./FormTitle.vue?vue&type=script&lang=ts"
export * from "./FormTitle.vue?vue&type=script&lang=ts"
import style0 from "./FormTitle.vue?vue&type=style&index=0&id=288802e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288802e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VIcon})
