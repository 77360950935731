import Vue from 'vue'
import Component from 'vue-class-component'
import {
  checkPreMepValidatorToErrorMessage, ErrorMessageType,
  isCheckPreMepValidatorWarning
} from '../../utils/CheckPreMep/CheckPremepValidatorUtils'
import { DspInstruction } from '../../types/instruction_type'

@Component({})
export class checkPremepValidatorMixins extends Vue {
  /**
   * Check if the user is a debugger or if the start status instruction is VALIDATED.
   * In fact, when one of these conditions is true, the error from the checkPreMepValidator are displayed as warning,
   * regardless if the CPMValidatorError has is_blocking_error ar false or true.
   */
  isWarningCPMValidator () {
    return isCheckPreMepValidatorWarning(
      this.$store.getters.isUserDebugger,
      this.$store.getters.getStartStatusInstruction
    )
  }

  getErrorRelatedToFieldCPMValidator (field: NestedKeyOf<DspInstruction>[]) {
    return this.getMessageRelatedToFieldCPMValidator(field, 'error')
  }

  getWarningRelatedToFieldCPMValidator (field: NestedKeyOf<DspInstruction>[]) {
    return this.getMessageRelatedToFieldCPMValidator(field, 'warning')
  }

  getMessageRelatedToFieldCPMValidator (field: NestedKeyOf<DspInstruction>[], messageType: ErrorMessageType) {
    const checkPreMepValidators = this.$store.getters.getCheckValidatorResult
    return checkPreMepValidatorToErrorMessage(checkPreMepValidators, field, messageType)
  }
}
