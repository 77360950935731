import {
  CheckValidatorResult,
  CPMValidatorError,
  DspInstruction,
  StatusInstruction
} from '../../types/instruction_type'
import _ from 'lodash'

export type ErrorMessageType = 'warning' | 'error'

/**
 * Get the CPMValidatorErrors in the CheckValidatorResult related with the field provided in parameter.
 * @param checkPreMepValidators
 * @param field
 * @param messageType If messageType is null, all the CPMValidatorErrors related with the field are returned.
 * If messageType is 'warning', only the CPMValidatorErrors with is_blocking_error = false are returned.
 * If messageType is 'error', only the CPMValidatorErrors with is_blocking_error = true are returned.
 */
export function getErrorMessageOfField (
  checkPreMepValidators: CheckValidatorResult,
  field: NestedKeyOf<DspInstruction>[],
  messageType: null | ErrorMessageType = null): CPMValidatorError[] {
  const errorMessages: CPMValidatorError[] = checkPreMepValidators.error_details
  return errorMessages.filter((error) => {
    let messageTypeCondition = true
    if (messageType === 'warning') {
      messageTypeCondition = !error.is_blocking_error
    } else if (messageType === 'error') {
      messageTypeCondition = error.is_blocking_error
    }
    return messageTypeCondition && _.intersection(error.instruction_columns, field).length > 0
  })
}

export function getCPMValidatorErrorMessage (validatorError: CPMValidatorError[]): string[] {
  return validatorError.map((error) => {
    return error?.error_message
  })
}

/**
 * Get the error message of the CPMValidatorError related with the field provided in parameter.
 * @param checkPreMepValidators
 * @param field
 * @param messageType
 */
export function checkPreMepValidatorToErrorMessage (
  checkPreMepValidators: CheckValidatorResult | null,
  field: NestedKeyOf<DspInstruction>[],
  messageType: null | ErrorMessageType = null): string[] {
  if (!checkPreMepValidators) {
    return []
  }
  const errorMessage: CPMValidatorError[] = getErrorMessageOfField(checkPreMepValidators, field, messageType)
  return getCPMValidatorErrorMessage(errorMessage)
}

/**
 * Check if the user is a debugger or if the start status instruction is VALIDATED.
 * When on of these conditions is true, the error from the checkPreMepValidator are displayed as warning
 * and or not blocking the user to save the instruction.
 * Otherwise, the error from the checkPreMepValidator are displayed as error
 * @param isUserDebugger
 * @param startStatusInstruction
 */
export function isCheckPreMepValidatorWarning (isUserDebugger: boolean, startStatusInstruction: StatusInstruction) {
  return isUserDebugger || startStatusInstruction === 'VALIDATED'
}

export function isCheckPreMepValidatorError (isUserDebugger: boolean, startStatusInstruction: StatusInstruction) {
  return !isCheckPreMepValidatorWarning(isUserDebugger, startStatusInstruction)
}
