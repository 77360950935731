
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, VModel } from 'vue-property-decorator'

@Component({})
export default class BaseWarningDialog extends Vue {
  @VModel({ type: Boolean })
  dialog!: boolean

  checked: {[key: string]: boolean} = {}

  @Prop()
  warningList: WarningList

  @Prop({ default: 'Cancel and edit' })
  labelCancel!: string

  @Prop({ default: 'Proceed with saving' })
  labelContinue!: string

  @Prop({ default: true })
  displayContinue!: boolean

  @Prop({ default: true })
  displayCancel!: boolean

  mounted () {
    this.initCheckedWithWarningList()
  }

  initCheckedWithWarningList () {
    console.warn('Init check')
    this.warningList.names.forEach(name => {
      Vue.set(this.checked, name, false)
    })
  }

  isAllChecked () {
    for (let key in this.checked) {
      if (this.checked[key] === false) {
        return false
      }
    }

    return true
  }

  resetChecked () {
    for (let key in this.checked) {
      if (!this.checked.hasOwnProperty(key)) {
        continue
      }
      this.checked[key] = false
    }
  }

  emitWarningChecked () {
    this.resetChecked()
    this.$emit('warning-checked')
  }

  close () {
    this.dialog = false
    this.resetChecked()
    this.$emit('close')
  }
}
