import FunnelIdModel, {
  AUDIBLE_VIEWABLE_COMPLETED,
  CLICK,
  COMPLETION,
  DSP,
  THIRD_PARTY,
  VIEWABILITY
} from '../Overlay/FunnelIdModel'
import _ from 'lodash'

/**
 * Contain Helper function for working with conversion funnel
 * Test available in ./tests/test_conversionFunnelHelper
 */
export {
  oneFunnelIsInThirdParty,
  getConversionFunnelDefaultValuePerKpi,
  isConversionFunnelWithoutId,
  isKpiHaveConversionFunnelId,
  areFunnelEqual,
  areConversionFunnelEqual
}

/**
 * Check in a conversionFunnel if one Funnel has her origin set as '3rd_party'
 * @param conversionFunnel {object<string, Array<FunnelIdModel>>}
 * @returns {boolean}
 */
function oneFunnelIsInThirdParty (conversionFunnel) {
  for (let key in conversionFunnel) {
    if (conversionFunnel.hasOwnProperty(key)) {
      const funnel = conversionFunnel[key]
      for (let index in funnel) {
        if (funnel.hasOwnProperty(index)) {
          const pixelObj = funnel[index]
          if (pixelObj.origin === THIRD_PARTY) {
            return true
          }
        }
      }
    }
  }
  return false
}

/**
 * @param kpiToOptimize {KpiValue}
 * return {{}|object<'1', Array<FunnelIdModel>>}
 */
function getConversionFunnelDefaultValuePerKpi (kpiToOptimize) {
  if (!isKpiHaveConversionFunnelId(kpiToOptimize)) {
    if (['CPC', 'CTR'].indexOf(kpiToOptimize) !== -1) {
      return { '1': [new FunnelIdModel(kpiToOptimize, CLICK, DSP)] }
    } else if (['CPCV', 'VTR'].indexOf(kpiToOptimize) !== -1) {
      return { '1': [new FunnelIdModel(kpiToOptimize, COMPLETION, DSP)] }
    } else if (['V-CPM'].indexOf(kpiToOptimize) !== -1) {
      return { '1': [new FunnelIdModel(kpiToOptimize, VIEWABILITY, DSP)] }
    } else if (['CPIAVC'].indexOf(kpiToOptimize) !== -1) {
      return { '1': [new FunnelIdModel(kpiToOptimize, AUDIBLE_VIEWABLE_COMPLETED, DSP)] }
    } else {
      return {}
    }
  }
  return {}
}

/**
 * check if the first pixel of the first funnel of a conversionFunnel has an id.
 * if he has an id, that mean it is CPA or CPA_PC, other Kpi_to_optimize dont have id on her pixel
 * @param conversionFunnel
 * @returns {boolean}
 */
function isConversionFunnelWithoutId (conversionFunnel) {
  return conversionFunnel['1'] === undefined ||
    conversionFunnel['1'][0] === undefined ||
    !conversionFunnel['1'][0].id
}

/**
 * is the kpi to optimize a Kpi who have a real conversion funnel pixel id (with pixel_ids)
 * @param kpiToOptimize {KpiValue}
 * @returns {boolean}
 */
function isKpiHaveConversionFunnelId (kpiToOptimize) {
  return ['CPA', 'CPA_PC'].indexOf(kpiToOptimize) !== -1
}

/**
 * @param funnel {ConversionFunnelInstruction}
 * @param otherFunnel {ConversionFunnelInstruction}
 * @return {boolean}
 */
function areConversionFunnelEqual (funnel, otherFunnel) {
  for (let key in funnel) {
    if (funnel.hasOwnProperty(key) && otherFunnel.hasOwnProperty(key)) {
      if (!areFunnelIdArrayEqual(funnel[key], otherFunnel[key])) {
        return false
      }
    } else {
      return false
    }
  }
  return true
}

/**
 * @param arr {Array<FunnelId>}
 * @param otherArr {Array<FunnelId>}
 */
function areFunnelIdArrayEqual (arr, otherArr) {
  let currentObjValue = _.cloneDeep(otherArr)
  if (arr.length !== otherArr.length) {
    return false
  }
  for (let i in arr) {
    let sameValue = currentObjValue.filter(item => areFunnelEqual(arr[i], item))
    if (!sameValue.length) {
      return false
    }
    const index = currentObjValue.indexOf(sameValue[0])
    if (index > -1) {
      currentObjValue.splice(index, 1)
    }
  }
  return true
}

/**
 * @param funnel {FunnelId}
 * @param otherFunnel {FunnelId}
 */
function areFunnelEqual (funnel, otherFunnel) {
  return funnel.id === otherFunnel.id && funnel.origin === otherFunnel.origin && funnel.type === otherFunnel.type
}
