import { DspInstruction, InstructionDsp, InstructionStrat, IoBrief, Instruction } from '../../types/instruction_type'
import { $DBM, $YOUTUBE } from '../../config/dspConfig'
import _ from 'lodash'

type CheckMode = 'error' | 'warning'
type CheckResponse = {check: boolean, maxMultiplier: null | number, multiplier: null | number}

const ERROR_MODE_PARAMS = 'mode param value should be "error" or "warning"'

/**
 * check if the new max KPICPA of a group of instruction is not to high compared to the last value of the
 * instruction strats in live of the io
 * @param optiAuto if opti_auto is true, the check will return always true
 * @param instructions
 * @param instructionStrats
 * @param dsp
 * @param mode depending of the mode (error or warning), the applied max_multiplier is not the same
 */
export function checkInStratsNewKpiCpaNotTooHigh (optiAuto: boolean,
  instructions: Instruction[],
  instructionStrats: InstructionStrat[], dsp: InstructionDsp, mode: CheckMode): CheckResponse {
  if (optiAuto || !instructionStrats.length) {
    return {
      check: true,
      maxMultiplier: null,
      multiplier: null
    }
  }

  const allKpiCpa = instructions.filter(item => item && item.objective && item.objective.KPI_CPA)
    .map(item => item.objective.KPI_CPA)
  const maxKpiCpa = Math.max(...allKpiCpa)

  const sortedInstruStratsByDate = _.cloneDeep(instructionStrats).sort((a, b) => {
    // we search for the most recent instruction strats
    const diffDays = b.insertion_day - a.insertion_day
    if (diffDays !== 0) {
      return diffDays
    }
    // days are equals, we sort with AB_params value
    return getABParamsValue(b, dsp) - getABParamsValue(a, dsp)
  })
  const lastKpiCpaInstruStrats = getABParamsValue(sortedInstruStratsByDate[0], dsp)
  const maxMultiplier = getMaxMultiplier(mode, dsp)
  let checkPass = isNewKpiNotToHigh(maxKpiCpa, lastKpiCpaInstruStrats, maxMultiplier)
  // is the lastKpi is less than 10^-4 (10e-5), we don't want to pop an error and disallow saving of the io
  if (mode === 'error' && lastKpiCpaInstruStrats <= 10e-5) {
    checkPass = true
  }
  return {
    check: checkPass,
    maxMultiplier: maxMultiplier,
    multiplier: getMultiplier(maxKpiCpa, lastKpiCpaInstruStrats)
  }
}

export function getMaxMultiplier (mode: CheckMode, dsp: InstructionDsp) {
  const TRUEVIEW_NON_BLOCKING = 2
  const TRUEVIEW_BLOCKING = 5
  const ALL_DSP_NON_BLOCKING = 5
  const ALL_DSP_BLOCKING = 9
  if (mode === 'warning') {
    return dsp === $YOUTUBE ? TRUEVIEW_NON_BLOCKING : ALL_DSP_NON_BLOCKING
  } else if (mode === 'error') {
    return dsp === $YOUTUBE ? TRUEVIEW_BLOCKING : ALL_DSP_BLOCKING
  }
  throw Error(ERROR_MODE_PARAMS)
}

export function isNewKpiNotToHigh (newKpi: number, lastKpi: number, multiplier: number) {
  return lastKpi * multiplier > newKpi
}

export function getMultiplier (newKpi: number, lastKpi: number) {
  return Math.floor(newKpi / lastKpi)
}

export function getABParamsValue (instruStrat: InstructionStrat, dsp: InstructionDsp) {
  if (dsp === $DBM && instruStrat.AB_effective && instruStrat.AB_effective.A != null) {
    return instruStrat.AB_effective.A
  }
  return instruStrat.AB_params && instruStrat.AB_params.A != null ? instruStrat.AB_params.A : 0
}

export function getCheckStratsErrorMessage (multiplier: number, mode: CheckMode) {
  if (mode === 'warning') {
    return `<span>You have input an A value that is more than <strong>${multiplier} times higher</strong> than the latest A value pushed.
           This might lead to <strong>severe overpacing</strong>, kindly make sure that you have input the right value.</span>`
  } else if (mode === 'error') {
    return `You have input an A value that is more than ${multiplier} times higher than the latest A value pushed.
            As this might lead to severe overpacing, please input a lower value.`
  }
  throw Error(ERROR_MODE_PARAMS)
}
