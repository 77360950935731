<template>
  <div>
    <div class="cursor-pointer" @click="show = !show">
      <v-btn icon>
        <v-icon>{{ show ? 'remove_circle_outline' : 'control_point' }}</v-icon>
      </v-btn>
      <span class="advanced-span">{{show ? message.hide : message.show }}</span>
    </div>

    <v-slide-y-transition>
      <slot v-bind:show="show"></slot>
    </v-slide-y-transition>
  </div>
</template>

<script>
export default {
  name: 'ShowAdvanced',
  props: {
    value: {
      type: Boolean
    },
    /**
     * must be a object like {show: 'show message', hide: 'hide message' }
     */
    message: {
      type: Object,
      default: function () {
        return {
          show: 'Show advanced fields',
          hide: 'Hide advanced fields'
        }
      }
    }
  },
  components: {

  },
  data: function () {
    return {
      show: false
    }
  },
  created: function () {

  },
  mounted: function () {
    this.show = this.value
  },
  methods: {
    resetShow () {
      this.show = false
    }
  },
  computed: {

  },
  watch: {
    value (val) {
      if (val !== this.show) {
        this.show = val
      }
    },
    show: {
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
  .advanced-span {
    font-size: 6px;
    color: #3788d8;
  }
  @media only screen
  and (min-width: 1824px) {
    .advanced-span {
      font-size: 8px;
    }
  }

</style>
