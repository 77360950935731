import { FunnelOrigin, FunnelType, KpiValue } from '../../../types/brief_enum'
import { FunnelId } from '../../../types/brief_type'

export const THIRD_PARTY = FunnelOrigin.THIRD_PARTY
export const DSP = FunnelOrigin.DSP

export const PIXEL = FunnelType.PIXEL
export const SEGMENT = FunnelType.SEGMENT
export const VIEWABILITY = FunnelType.VIEWABILITY
export const CLICK = FunnelType.CLICK
export const COMPLETION = FunnelType.COMPLETION
export const AUDIBLE_VIEWABLE_COMPLETED = FunnelType.AUDIBLE_VIEWABLE_COMPLETED

class FunnelIdModel implements FunnelId {
  /**
   * => https://trello.com/c/cAUFWgof/407-surcouche-all-funnel-changement-sur-la-nomenclature-du-champ-pdp
   */
  constructor (
    kpi: KpiValue,
    public type: FunnelType = FunnelType.PIXEL,
    public origin: FunnelOrigin = FunnelOrigin.DSP,
    public id = '') {
    if ([KpiValue.CPA, KpiValue.CPA_PC].indexOf(kpi) !== -1) {
      this.id = String(id)
    }
    this.type = type
    this.origin = origin
  }
}

export default FunnelIdModel
