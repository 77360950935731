<template>
  <v-row
   wrap
    v-if="isActivated"
  >
    <!-- INHERIT FROM EXPECTED MARGIN-->
    <v-col cols="6">
      <v-checkbox
        :label="labelCheckBox"
        v-model="model"
        tab="tab1"
      >
      </v-checkbox>
    </v-col>
    <v-col cols="6">
      <v-row>

        <v-col cols="6">
          <v-text-field
            :label="label"
            v-show="!value"
            :value="inheritInput"
            @input="updateInheritInput($event)"
            :mask="mask"
            class="inherit-text-field"
            tab="tab1"
            :rules="!model ? rules : []"
            :error="error"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            class="invisible-input-slot-error-message error-color"
            :error-messages="errorMessages"
            tab="tab1"
          >
          </v-text-field>
          <v-text-field
            class="invisible-input-slot-error-message hint-color"
            :hint="hint"
            :persistent-hint="!!hint"
            tab="tab1"
          >
          </v-text-field>
        </v-col>

      </v-row>

    </v-col>

  </v-row>
</template>

<script>
export default {
  name: 'InheritInput',
  props: {
    value: {
      type: Boolean
    },
    label: {
      type: String
    },
    labelCheckBox: {
      type: String
    },
    errorMessages: {
      type: [Array, String],
      default: function () {
        return []
      }
    },
    rules: {
      type: Array,
      default: function () {
        return []
      }
    },
    mask: {
      type: [String, null],
      default: null
    },
    inheritInput: {
      type: [Number, String]
    },
    isActivated: {
      type: Boolean
    },
    error: {
      type: Boolean
    },
    hint: {
      type: String
    }
  },
  data: function () {
    return {
      model: null
    }
  },
  mounted () {
    this.model = this.value
  },
  methods: {
    updateInheritInput (value) {
      if (!isNaN(value) && typeof value !== 'number') {
        value = value !== '' ? Number(value) : ''
      }
      this.$emit('update:inheritInput', value)
    }
  },
  watch: {
    value (val) {
      if (val !== this.model) {
        this.model = val
      }
    },
    model: {
      handler: function (val) {
        this.$emit('input', val)
      }
    }

  }
}
</script>

<style scoped>
.inherit-text-field {
  width: 90%;
}

</style>
