<template>
  <v-card v-if="insGetterSetter" class="instru-form pa-2" :class="{'warning-form': isInstructionWarning()}">

    <!-- GROUPNAME LAYOUT -->
    <v-row wrap>
      <v-col cols="1" class="rm-instruction-div">
        <v-row wrap class="mx-auto justify-center">
          <div class="pa-2">
             <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" small fab color="#AAAAAA" v-if="Number(index) !== 0" @click="emitRemoveInstruction()">
                    <v-icon color="white">remove</v-icon>
                  </v-btn>
                </template>
                <span>Remove the instruction</span>
             </v-tooltip>
          </div>
          <div class="pa-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="duplicate-instru-icon" color="gray" @click="emitDuplicate()">file_copy</v-icon>
              </template>
              <span>Duplicate the instruction</span>
            </v-tooltip>
          </div>
          <div class="pa-2" v-show="$store.getters.isInstructionStratEnabled">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="outcomes-instru-icon" color="gray" @click="emitOpenOutcomes()">remove_red_eye</v-icon>
              </template>
              <span>Open outcomes for this instruction</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-subheader class="title-instru-form">Instruction {{computedIndex}}</v-subheader>
        <div class="info-date-id-instruform">
          <div v-if="editedItem.id"><span class="label-info">ID : </span>{{editedItem.id}}</div>
          <div v-if="editedItem.insertion_date"><span class="label-info">Insertion date : </span>{{$transformIsoDate(editedItem.insertion_date, false)}}</div>
        </div>
      </v-col>
      <v-col cols="5">
        <v-text-field
          label="Group Name"
          v-model.trim="insGetterSetter.groupName"
          :rules="[rules.required]"
          tab="tab2"
          :error-messages="groupNameErrorMessage()"
          class="selenium-group-name"
        >
        </v-text-field>
      </v-col>

      <v-col cols="2">
        <div class="cursor-pointer" @click="showAdvanced = !showAdvanced">
          <v-btn icon >
            <v-icon color="#3788D8">{{ showAdvanced ? 'remove' : 'add' }}</v-icon>
          </v-btn>
          <span class="advanced-span noselect">{{showAdvanced ? 'Hide advanced fields' : 'Show advanced fields' }}</span>
        </div>
      </v-col>

      <v-col cols="2">
        <v-row wrap>
            <v-col cols="4">
              <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="toggleItemValue('is_active')" :class="(editedItem.is_active ? 'green-lens' : 'red-lens')" small>lens</v-icon>
                  </template>
                  <span> Active : {{editedItem.is_active == null ? 'false' : editedItem.is_active.toString()}} </span>
              </v-tooltip>
            </v-col>
        </v-row>
      </v-col>
    </v-row>

  <!-- LINE ITEMS LAYOUT -->
    <v-row
   wrap
    >
      <v-col cols="6">
        <v-row wrap>
          <v-col cols="1"  class="label-li-ca">
            <span>{{lineItemsLabel}}</span>
          </v-col>
          <v-col cols="3">
            <v-select
              :disabled="(insGetterSetter.preCA !== 'all' && $route.params.dsp === $APPNEXUS)"
              :items="input.lineitems"
              v-model.trim="preLineItem"
              tab="tab2"
            >
            </v-select>
          </v-col>
          <v-col cols="8">
            <v-text-field
              :disabled="preLineItem === 'all' || (insGetterSetter.preCA !== 'all' && $route.params.dsp === $APPNEXUS)"
              :error-messages="lineItemsErrorMessage('li')"
              v-model.trim="idLineItem"
              label="xxxx,xxxx,xxxx"
              tab="tab2"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  <!-- OBJECTIVES LAYOUT -->
    <v-row
   wrap
    class="objectives-layout"
    >
        <v-col cols="2" v-if="isFieldEnabledPerDsp('kpiCpa')">
          <v-slide-y-transition>
            <v-text-field
              :type="`number`"
              class="no-spinners objectives-fields selenium-obj-kpi"
              label="KPI CPA"
              v-model.trim="insGetterSetter.kpiCpa"
              v-show="!optiAuto"
              :error-messages="kpiCpaErrorsRules()"
              :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
              tab="tab2"
            >
            </v-text-field>
          </v-slide-y-transition>
        </v-col>

        <v-col cols="2" v-if="isFieldEnabledPerDsp('offset')">
          <v-text-field
            class="no-spinners objectives-fields selenium-obj-offset"
            v-show="!optiAuto"
            :type="`number`"
            label="Offset"
            v-model.trim="insGetterSetter.offset"
            :rules="[rules.numericAllowUndef]"
            :error-messages="offsetRule()"
            tab="tab2"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1" v-if="isFieldEnabledPerDsp('minViz')">
          <v-text-field
            v-if="$route.params.dsp !== $DBM"
            v-show="showMinVizInput()"
            class="no-spinners objectives-fields selenium-obj-minViz"
            label="viz filter"
            v-model.trim="insGetterSetter.minViz"
            :error-messages="isBetweenZeroAndHundred(insGetterSetter.minViz)"
            suffix="%"
            :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
            :mask="digitMask[3]"
            tab="tab2"
          >
          </v-text-field>
          <!-- special case for dbm, a select field is needed -->
          <v-tooltip bottom :disabled="!keepMinViz" v-else-if="$route.params.dsp === $DBM">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-select
                  v-show="showMinVizInput()"
                  class="no-spinners objectives-fields selenium-obj-minViz"
                  label="viz filter"
                  v-model.trim="insGetterSetter.minViz_select"
                  :items="input.minViz_select"
                  :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
                  tab="tab2"
                  :disabled="keepMinViz"
                >
                </v-select>
              </div>
            </template>
            <span>
              Viz filter is not available as viewability overwriting is not allowed
            </span>
          </v-tooltip>
        </v-col>
        <!-- TODO : ENABLED  FOR 0 DSP, delete it ? -->
        <v-col cols="1" v-if="isFieldEnabledPerDsp('minVideo')">
          <v-text-field
            class="no-spinners objectives-fields selenium-min-video"
            label="video viz filter"
            v-model.trim="insGetterSetter.minVideo"
            :error-messages="isBetweenZeroAndHundred(insGetterSetter.minVideo)"
            suffix="%"
            :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
            :mask="digitMask[3]"
            tab="tab2"
          >
          </v-text-field>
        </v-col>

        <!-- special FACEBOOK fields-->
        <v-col cols="2" v-if="isFieldEnabledPerDsp('max_budget')">
          <v-text-field
            :type="`number`"
            class="no-spinners objectives-fields selenium-obj-maxBudget"
            label="max budget"
            v-model.trim="insGetterSetter.maxBudget"
            :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
            :error-messages="isBetweenRule(insGetterSetter.maxBudget, 0, 100000)"
            tab="tab2"
          >
          </v-text-field>
        </v-col>

        <v-col cols="2" v-if="isFieldEnabledPerDsp('min_budget')">
          <v-text-field
            :type="`number`"
            class="no-spinners objectives-fields selenium-obj-minBudget"
            label="min budget"
            v-model.trim="insGetterSetter.minBudget"
            :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
            :error-messages="isBetweenRule(insGetterSetter.minBudget, 0, 100000)"
            tab="tab2"
          >
          </v-text-field>
        </v-col>
        <!-- end special FACEBOOK fields-->
        <v-col cols="2" v-if="isFieldEnabledPerDsp('maxCpm')">
          <v-slide-y-transition>
            <NewFeatureReferenceEncapsulation
              v-show="dsp != null && keystoneMemberSetting.id != null && showAdvanced"
              :nfrDevName="maxBidDevName"
              :dsp="dsp"
              :application="'member'"
              :entityId="keystoneMemberSetting.id"
              :specialCondition="dsp !== 'youtube'"
            >
              <template v-slot:basicFeature>
                <v-text-field
                  :type="`number`"
                  class="no-spinners objectives-fields selenium-obj-maxCpm"
                  :label="labelMaxBid"
                  v-model.trim="insGetterSetter.maxCpm"
                  :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
                  :error-messages="errorMessageMaxBid()"
                  tab="tab2"
                />
              </template>
              <template v-slot:nfr>
                <v-row>
                  <v-col>
                    <v-text-field
                      :type="`number`"
                      class="no-spinners selenium-obj-maxCpm"
                      :label="labelMaxBid"
                      v-model.trim="insGetterSetter.maxCpm"
                      :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
                      :error-messages="errorMessageMaxBid()"
                      tab="tab2"
                    />
                  </v-col>
                  <v-col class="shrink" align-self-baseline>
                    <v-tooltip  right>
                      <template v-slot:activator="{ on }">
                        <v-icon class="mt-1" v-on="on" :color="insGetterSetter.maxCpm == null || insGetterSetter.maxCpm === '' ? 'orange' : 'red'">
                          {{'info_outline'}}
                        </v-icon>
                      </template>
                      <span>
                          <span v-if="insGetterSetter.maxCpm == null || insGetterSetter.maxCpm === ''">
                            Max bid value will be automatically calculated by Math code given CPM & Margin constraints
                          </span>
                          <span v-else>
                            This max Bid value will replace the automatic Max bid taken into account by Math code
                          </span>
                        </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </template>
            </NewFeatureReferenceEncapsulation>
          </v-slide-y-transition>
        </v-col>
        <v-col cols="2" v-if="isFieldEnabledPerDsp('optiRatio')">
          <v-slide-y-transition>
            <v-text-field
              class="no-spinners objectives-fields attri-ratio selenium-attri-ratio"
              :type="`number`"
              label="attribution ratio"
              v-model.trim="insGetterSetter.optiRatio"
              v-show="showAdvanced && useSurcouche"
              :rules="[rules.numericAllowUndef, rules.noNegativeValue]"
              :error-messages="errorsMessageAttributionRatio()"
              tab="tab2"
              :persistent-hint="true"
            >
            </v-text-field>
          </v-slide-y-transition>
        </v-col>

    </v-row>

    <VizFilterTtdComponent
      :edited-item="editedItem"
      :overwrite-viewability="overwriteViewability"
      :key="editedItem.id"
      :otto-min-viz="ottoMinViz"
    >
    </VizFilterTtdComponent>
    <!-- ERROR BOX MESSAGE INSTRUCTION -->
    <v-row
     wrap
      v-show="generalErrorMessage.length !== 0"
      class="error-message-layout"
    >
      <v-col>
        <v-text-field
          :error-messages="generalErrorMessage"
          class="invisible-input-slot-error-message"
          tab="tab2"
          :error-count="4"
        >
        </v-text-field>
      </v-col>
    </v-row>
  <!-- -->
  </v-card>
</template>

<script>
import { rulesMixin } from '../../../../mixins/rulesMixin'
import VizFilterTtdComponent from '@/components/TableComponents/Form/FormFragments/VizFilterTtdComponent'
import { getFullInsertionOrderOfInstruction } from '../../../../../utils/instructionsUtils'
import InstructionGetterSetter from '../../../../models/Overlay/InstructionGetterSetter'
import { instructionsMixins } from '../../../../mixins/instructions'
import { instructionsFieldEnabledPerDsp } from '../../../../../config/dspConfig'
import NewFeatureReferenceEncapsulation from '../../../NewFeatureReferenceEncapsulation/NewFeatureReferenceEncapsulation.vue'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import { maxBidDevName } from '../../../../../config/featureReferenceConstant'
import {
  checkInStratsNewKpiCpaNotTooHigh,
  getCheckStratsErrorMessage
} from '../../../../../utils/CheckStrats/CheckStrats'

export default {
  name: 'InstructionForm',
  mixins: [instructionsMixins, rulesMixin],
  props: {
    index: {
      type: Number,
      required: true
    },
    optiAuto: {
      type: Boolean,
      required: true
    },
    ottoMinViz: {
      type: Boolean,
      required: true
    },
    useSurcouche: {
      type: Boolean,
      required: true
    },
    offsetCanBeSetted: {
      type: Boolean,
      required: true
    },
    isDislayErrorMessageOptiRatio: {
      type: Boolean,
      required: true
    },
    keepMinViz: {
      type: Boolean,
      required: true
    },
    overwriteViewability: {
      type: Boolean,
      required: false
    },
    keystoneMemberSetting: {
      type: MemberSettingModel,
      required: true
    },
    stratInfo: {
      type: Array,
      required: true
    },
    kpiToOptimize: {
      type: String,
      required: false
    }
  },
  components: {
    VizFilterTtdComponent,
    NewFeatureReferenceEncapsulation

  },
  data: function () {
    return {
      /**
       * @type {InstructionGetterSetter | null}
       */
      insGetterSetter: new InstructionGetterSetter(this.editedItem, this.dsp),
      input: {
        lineitems: ['all', 'only', 'all but'],
        minViz_select: [
          {
            text: 'none',
            value: ''
          },
          {
            text: '0.1',
            value: 0.1
          },
          {
            text: '0.2',
            value: 0.2
          },
          {
            text: '0.3',
            value: 0.3
          },
          {
            text: '0.4',
            value: 0.4
          },
          {
            text: '0.5',
            value: 0.5
          },
          {
            text: '0.6',
            value: 0.6
          },
          {
            text: '0.7',
            value: 0.7
          },
          {
            text: '0.8',
            value: 0.8
          },
          {
            text: '0.9',
            value: 0.9
          }
        ]
      },
      showAdvanced: false,
      // objective fields enabled for each dsp.
      // if a dsp has a array with a value equal to '*' or if the dsp is equal to '*', all field are enabled
      // POSSIBLE VALUE :
      // 'kpiCpa',
      // 'maxCpm',
      // 'cRate',
      // 'offset',
      // 'minViz',
      // 'minVideo',
      // 'optiRatio',
      // 'max_budget',
      // 'min_budget'
      enabledPerDsp: instructionsFieldEnabledPerDsp,
      keystoneSettingsMemberId: null
    }
  },
  mounted: function () {
    this.insGetterSetter = new InstructionGetterSetter(this.editedItem, this.$route.params.dsp)
    this.showAdvanced = !this.isAdvancedFieldInDefaultValue() || this.useSurcouche

    // For forcing trim of all the elem (see getOptimizeIds) for corrupted data in datastore (following issue => PAIN-1185 : https://scibids-k.atlassian.net/jira/software/projects/PAIN/boards/10?selectedIssue=PAIN-1185 )
    if (typeof this.idLineItem === 'string') {
      this.idLineItem = this.idLineItem.trim()
    }
  },
  methods: {
    toggleItemValue (field) {
      this.$set(this.editedItem, field, !this.editedItem[field])
    },
    emitRemoveInstruction () {
      this.$emit('remove-instruction', this.editedItem)
    },
    groupNameErrorMessage () {
      if (!this.testAsciiChars(this.insGetterSetter.groupName)) {
        return ['Must contain only ASCII characters. (Cannot contain accents)']
      }

      if (!isNaN(Number(this.insGetterSetter.groupName))) {
        return ['Group name can not be a numeric value']
      }

      let len = this.insGetterSetter?.groupName?.length
      if (len < 2 || len > 200) {
        return ['Group name must contain between 2 and 200 characters']
      }
    },
    /**
     * @param type {String} li | ca => li if optimize_lineitems, ca if optimize_campaigns
     */
    lineItemsErrorMessage (type) {
      if (type === 'li') {
        if (this.preLineItem !== 'all' && this.idLineItem === '') {
          return 'You need to set this field'
        }
      } else if (type === 'ca') {
        if (this.preCA !== 'all' && this.idCa === '') {
          return 'You need to set this field'
        }
      } else {
        throw new Error("The type argument must be one of them : 'li' or 'ca'")
      }
    },
    isAdvancedFieldInDefaultValue () {
      let check = (dsp) => {
        let defaultItem = this.$store.getters.getDefaultItem
        switch (dsp) {
          case this.$APPNEXUS:
            return (this.editedItem.opti_ratio === undefined || this.editedItem.opti_ratio === null || this.editedItem.opti_ratio === defaultItem.opti_ratio) &&
              this.editedItem.optimize_campaigns === defaultItem.optimize_campaigns
          case this.$THETRADEDESK:
            return (this.editedItem.opti_ratio === undefined || this.editedItem.opti_ratio === null || this.editedItem.opti_ratio === defaultItem.opti_ratio)
          default:
            return (this.editedItem.opti_ratio === undefined || this.editedItem.opti_ratio === null || this.editedItem.opti_ratio === defaultItem.opti_ratio) &&
              this.editedItem.optimize_campaigns === defaultItem.optimize_campaigns
        }
      }
      return check(this.$route.params.dsp)
    },
    emitDuplicate () {
      this.$emit('duplicate-instruction', this.index)
    },
    emitOpenOutcomes () {
      const dsp = this.$route.params.dsp
      let ioId = getFullInsertionOrderOfInstruction(this.editedItem, dsp)
      this.$emit('open-strat', ioId, this.editedItem.id)
    },
    /**
     * error message rules for KPI CPA.
     * if Activate Otto disabled, KPI CPA must be filled
     */
    kpiCpaErrorsRules () {
      let errors = []
      if (!this.optiAuto && (this.insGetterSetter.kpiCpa === '' || this.insGetterSetter.kpiCpa === undefined || this.insGetterSetter.kpiCpa === null)) {
        let message = 'Kpi Cpa must be filled if Activate Otto is disabled'
        errors.push(message)
      }

      if (this.$route.params.dsp === this.$YOUTUBE) {
        if (!(this.insGetterSetter.kpiCpa === '' || this.insGetterSetter.kpiCpa === undefined || this.insGetterSetter.kpiCpa === null) &&
          this.kpiToOptimize !== 'CPVideoView' &&
          !this.optiAuto &&
          this.$commonUtils.countDecimal(this.insGetterSetter.kpiCpa) > 3) {
          let message = 'decimal values that exceed 3 digits are not DV360-compatible'
          errors.push(message)
        }
      }
      const isUserDebugger = this.$store.getters.isUserDebugger

      if (!isUserDebugger) {
        const checkStratsResult = checkInStratsNewKpiCpaNotTooHigh(
          this.optiAuto, [this.editedItem], this.stratInfo, this.dsp, 'error')

        if (!checkStratsResult.check) {
          let message = getCheckStratsErrorMessage(checkStratsResult.maxMultiplier, 'error')
          errors.push(message)
        }
      }

      return errors
    },
    offsetRule () {
      let btwResult = this.isBetweenRule(this.insGetterSetter.offset, -1000000, 1000000)
      let offsetRuleResult = this.offsetRuleExecutor()
      if (btwResult !== undefined) {
        return btwResult
      } else if (offsetRuleResult !== undefined && this.insGetterSetter.offset) {
        return offsetRuleResult
      }
    },
    offsetRuleExecutor () {
      if (!this.offsetCanBeSetted) {
        return 'Offset can\'t be setted if executor version is set to 2.'
      }
    },
    /**
     * @returns {Boolean}
     */
    isFieldEnabledPerDsp (field) {
      let dsp = this.$route.params.dsp

      if (Object.keys(this.enabledPerDsp).indexOf(dsp) === -1) {
        console.warn(`enabledPerDsp has not a key equal to the dsp ${dsp}. No "objective" field will be displayed.`)
        return false
      }

      let enabledPerDsp = this.enabledPerDsp[dsp]
      // if enabledPerDsp === * or contain '*', all field are enabled
      return enabledPerDsp === '*' || enabledPerDsp.indexOf(field) !== -1 || (enabledPerDsp.indexOf('*') !== -1 && enabledPerDsp.length === 1)
    },
    isInstructionWarning () {
      let instructionInWarning = this.$store.getters.getInstructionsInWarning
      return instructionInWarning.indexOf(this.editedItem.id) !== -1
    },
    errorsMessageAttributionRatio () {
      // undef if no error
      const errorMessageBetweenRule = this.isBetweenRule(this.insGetterSetter.optiRatio, 0, 10)
      let errorOptiRatio = []
      if (this.useSurcouche && this.isDislayErrorMessageOptiRatio && this.optiRatioIsEmpty) {
        errorOptiRatio = ['Must be filled if at least one instruction has a ratio']
      }

      if (errorMessageBetweenRule) {
        return [errorMessageBetweenRule, ...errorOptiRatio]
      }

      return errorOptiRatio
    },
    errorMessageMaxBid () {
      const errors = []

      if (this.insGetterSetter.maxCpm !== '' && this.insGetterSetter.maxCpm != null && this.$commonUtils.isNumeric(this.insGetterSetter.maxCpm)) {
        let message = this.isBetweenRule(this.insGetterSetter.maxCpm, 0, 1000000, null, true)
        if (message) {
          errors.push(message)
        }
      }
      return errors
    },
    /**
     * Min viz input is disabled if ottoMinViz is true
     * @returns {boolean}
     */
    showMinVizInput () {
      return !this.ottoMinViz
    }
  },
  computed: {
    labelMaxBid () {
      let dsp = this.$route.params.dsp
      const maxBidAffect = dsp === this.$MEDIAMATH ? 'total_spend' : 'media_cost'
      return `max bid (${maxBidAffect})`
    },
    computedIndex: function () {
      return Number(this.index) >= 9 ? (Number(this.index) + 1).toString() : '0' + (Number(this.index) + 1).toString()
    },
    preLineItem: {
      get: function () {
        let dsp = this.$route.params.dsp

        if (dsp === this.$THETRADEDESK) {
          return this.insGetterSetter.preAD
        } else if (dsp === this.$APPNEXUS || dsp === this.$DBM) {
          return this.insGetterSetter.preLI
        } else if (dsp === this.$MEDIAMATH) {
          return this.insGetterSetter.preSTR
        } else if (dsp === this.$META) {
          return this.insGetterSetter.preADSET
        } else if (dsp === this.$KAYZEN) {
          return this.insGetterSetter.preCA
        } else { // default
          return this.insGetterSetter.preLI
        }
      },
      set: function (value) {
        let dsp = this.$route.params.dsp

        if (dsp === this.$THETRADEDESK) {
          this.insGetterSetter.preAD = value
        } else if (dsp === this.$APPNEXUS || dsp === this.$DBM) {
          this.insGetterSetter.preLI = value
        } else if (dsp === this.$MEDIAMATH) {
          this.insGetterSetter.preSTR = value
        } else if (dsp === this.$META) {
          this.insGetterSetter.preADSET = value
        } else if (dsp === this.$KAYZEN) {
          this.insGetterSetter.preCA = value
        } else {
          this.insGetterSetter.preLI = value
        }
      }
    },
    idLineItem: {
      get: function () {
        let dsp = this.$route.params.dsp

        if (dsp === this.$THETRADEDESK) {
          return typeof this.insGetterSetter.idAd === 'string' ? this.insGetterSetter.idAd.trim() : this.insGetterSetter.idAd
        } else if (dsp === this.$APPNEXUS || dsp === this.$DBM) {
          return this.insGetterSetter.idLi
        } else if (dsp === this.$MEDIAMATH) {
          return this.insGetterSetter.idStr
        } else if (dsp === this.$META) {
          return this.insGetterSetter.idAdSet
        } else if (dsp === this.$KAYZEN) {
          return this.insGetterSetter.idCa
        } else {
          return this.insGetterSetter.idLi
        }
      },
      set: function (value) {
        let dsp = this.$route.params.dsp
        value = value.trim()
        if (dsp === this.$THETRADEDESK) {
          this.insGetterSetter.idAd = value
        } else if (dsp === this.$APPNEXUS || dsp === this.$DBM) {
          this.insGetterSetter.idLi = value
        } else if (dsp === this.$MEDIAMATH) {
          this.insGetterSetter.idStr = value
        } else if (dsp === this.$META) {
          this.insGetterSetter.idAdSet = value
        } else if (dsp === this.$KAYZEN) {
          this.insGetterSetter.idCa = value
        } else {
          this.insGetterSetter.idLi = value
        }
      }
    },
    lineItemsLabel () {
      let dsp = this.$route.params.dsp
      if ([this.$APPNEXUS, this.$DBM].indexOf(dsp) !== -1) {
        return 'li'
      } else if ([this.$THETRADEDESK, this.$FACEBOOK].indexOf(dsp) !== -1) {
        return 'ad'
      } else if (dsp === this.$MEDIAMATH) {
        return 'str'
      } else { // default
        return 'li'
      }
    },
    generalErrorMessage () {
      return this.mustBeGreaterRules(this.insGetterSetter.maxBudget, this.insGetterSetter.minBudget, 'max budget', 'min budget')
    },
    optiRatioIsEmpty () {
      return this.editedItem.opti_ratio === undefined || this.editedItem.opti_ratio === null || this.editedItem.opti_ratio === ''
    },
    dsp () {
      return this.$route.params.dsp
    },
    maxBidDevName () {
      return maxBidDevName
    }
  },
  watch: {
    useSurcouche: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.showAdvanced = true
        }
      }
    }
  }
}
</script>

<style>
  .title-viz-ttd {
    font-size: 8px;
    text-align: center;
    color: gray;
  }

  .instru-form {
    border-radius: 45px !important;
    border: 0.5px solid black;
    width: 100%;
  }
  .title-instru-form {
    padding-top: 10px;
    margin-top: 7px;
  }
  .label-li-ca {
    /* padding: 1.5em; */
    padding-top: 1.5em;
  }
  .objectives-layout {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .rm-instruction-div {
    text-align: center;
  }
  .objectives-fields {
    padding-left: 0.4em;
    padding-right: 0.4em;
  }
  .instru-form button.v-btn.v-btn--icon.theme--light {
    margin: 0;
  }

  .instruction-form-container  .instru-form.warning-form {
    border: 2px solid orange;
  }

  button.v-icon.duplicate-instru-icon.material-icons.theme--light.gray--text,
  button.v-icon.outcomes-instru-icon.material-icons.theme--light.gray--text {
    padding: 3px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 45px;
  }

  button.v-icon.duplicate-instru-icon.material-icons.theme--light.gray--text:hover,
  button.v-icon.outcomes-instru-icon.material-icons.theme--light.gray--text:hover {
    background-color: lightgray;
  }

  .info-date-id-instruform {
    font-size: 0.6rem;
    color: gray;
    font-weight: 400;
  }
  .info-date-id-instruform .label-info {
    font-weight: 500;
  }

  .attri-ratio .v-messages__message {
    color: orange;
  }

  .attri-ratio .error--text .v-messages__message  {
    color: red;
  }

  .error-message-layout {
    margin-left: 0.8em;
  }

  @media only screen
  and (max-width: 1823px) {

  }

  @media only screen
  and (min-width: 1824px) {
    .info-date-id-instruform {
      font-size: 0.7rem;
    }
  }

</style>
