<template>
  <v-row wrap justify-space-around v-if="insGetterSetter">
    <!-- special THETRADEDESK fields -->
    <v-col class="px-6" cols="12" v-if="$route.params.dsp === $THETRADEDESK && areDisabledVizFieldsTtd">
      <v-alert type="info">
        Viz filters are not available as viewability overwriting is not allowed
      </v-alert>
    </v-col>
    <v-col cols="4" v-if="$route.params.dsp === $THETRADEDESK && !areDisabledVizFieldsTtd">
      <v-row wrap>
        <v-col cols="12" class="title-viz-ttd">
          <b>Display</b> viz filter
        </v-col>
        <v-col cols="6">
          <v-select
            label="partner"
            :items="input.itemsPartnerTtdMinViz"
            v-model="insGetterSetter.vizPartner"
            :readonly="isOttoMinVizEnabled"
          >
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-if="!isOttoMinVizEnabled"
            label="value"
            :disabled="isDisabledTtdMinViz"
            :items="itemsTtdMinViz"
            v-model="insGetterSetter.minVizTtd"
            :rules="isDisabledTtdMinViz ? [] : [rules.required]"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="4" v-if="$route.params.dsp === $THETRADEDESK && !areDisabledVizFieldsTtd">
      <v-row wrap>
        <v-col cols="12" class="title-viz-ttd">
          <b>Video</b> viz filter
        </v-col>
        <v-col cols="6">
          <v-select
            label="partner"
            :items="input.itemsPartnerTtdMinVideoViz"
            v-model="insGetterSetter.videoVizPartner"
            :readonly="isOttoMinVizEnabled"
          >
          </v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-if="!isOttoMinVizEnabled"
            label="value"
            :disabled="isDisabledTtdMinVideoViz"
            :items="itemsTtdMinVideoViz"
            v-model="insGetterSetter.minVideoTtd"
            :rules="isDisabledTtdMinVideoViz ? [] : [rules.required]"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <!-- end special THETRADEDESK fields -->
  </v-row>
</template>

<script>
import { rulesMixin } from '@/mixins/rulesMixin'
import { instructionsMixins } from '../../../../mixins/instructions'
import InstructionGetterSetter from '../../../../models/Overlay/InstructionGetterSetter'

export default {
  name: 'VizFilterTtdComponent',
  props: {
    overwriteViewability: {
      type: Boolean,
      required: false
    },
    ottoMinViz: {
      type: Boolean,
      required: true
    }
  },
  mixins: [instructionsMixins, rulesMixin],
  data: function () {
    return {
      /**
       * @type {InstructionGetterSetter | null}
       */
      insGetterSetter: new InstructionGetterSetter(this.editedItem, this.dsp),
      input: {
        itemsPartnerTtdMinViz: [
          {
            text: 'none',
            value: null
          },
          {
            text: 'quality_alliance',
            value: 'quality_alliance'
          },
          {
            text: 'double_verify',
            value: 'double_verify'
          },
          {
            text: 'ias',
            value: 'ias'
          },
          {
            text: 'moat',
            value: 'moat'
          },
          {
            text: 'peer39',
            value: 'peer39'
          }
        ],
        itemsPartnerTtdMinVideoViz: [
          {
            text: 'none',
            value: null
          },
          {
            text: 'quality_alliance',
            value: 'quality_alliance'
          },
          {
            text: 'double_verify',
            value: 'double_verify'
          },
          {
            text: 'ias',
            value: 'ias'
          },
          {
            text: 'moat',
            value: 'moat'
          }
        ],
        itemsTtdMinViz: {
          quality_alliance: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90],
          double_verify: [0.3, 0.4, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8],
          ias: [0.4, 0.5, 0.6, 0.7],
          peer39: [0.6, 0.8],
          moat: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8]
        },
        itemsTtdMinVideoViz: {
          quality_alliance: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90],
          double_verify: [0.3, 0.4, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8],
          ias: [0.4, 0.5, 0.6, 0.7],
          moat: [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8]
        }
      }
    }
  },
  mounted () {
    this.insGetterSetter = new InstructionGetterSetter(this.editedItem, this.$route.params.dsp)
  },
  methods: {},
  computed: {
    areDisabledVizFieldsTtd () {
      return !this.overwriteViewability
    },
    itemsTtdMinVideoViz () {
      return this.insGetterSetter.videoVizPartner in this.input.itemsTtdMinVideoViz
        ? this.input.itemsTtdMinVideoViz[this.insGetterSetter.videoVizPartner]
        : []
    },
    itemsTtdMinViz () {
      return this.insGetterSetter.vizPartner in this.input.itemsTtdMinViz
        ? this.input.itemsTtdMinViz[this.insGetterSetter.vizPartner]
        : []
    },
    isDisabledTtdMinViz () {
      return this.input.itemsPartnerTtdMinViz.filter(item => item.value !== null).map(item => item.value).indexOf(this.insGetterSetter.vizPartner) === -1
    },
    isDisabledTtdMinVideoViz () {
      return this.input.itemsPartnerTtdMinVideoViz.filter(item => item.value !== null).map(item => item.value).indexOf(this.insGetterSetter.videoVizPartner) === -1
    },
    isOttoMinVizEnabled () {
      console.warn('isOttoMinVizEnabled', this.ottoMinViz)
      return this.$route.params.dsp === this.$THETRADEDESK && this.ottoMinViz
    }
  },
  watch: {
    'insGetterSetter.videoVizPartner': {
      immediate: true,
      handler: function (value) {
        let dsp = this.$route.params.dsp
        if (!value && dsp === this.$THETRADEDESK) {
          this.insGetterSetter.minVideoTtd = null
          if (value !== null) {
            this.insGetterSetter.videoVizPartner = null
          }
        }
      }
    },
    'insGetterSetter.vizPartner': {
      immediate: true,
      handler: function (value) {
        let dsp = this.$route.params.dsp
        if (!value && dsp === this.$THETRADEDESK) {
          this.insGetterSetter.minVizTtd = null
          if (value !== null) {
            this.insGetterSetter.vizPartner = null
          }
        }
      }
    },
    ottoMinViz: {
      immediate: true,
      handler: function (ottoMinViz) {
        let dsp = this.$route.params.dsp
        if (dsp === this.$THETRADEDESK && ottoMinViz) {
          this.insGetterSetter.videoVizPartner = 'double_verify'
          this.insGetterSetter.vizPartner = 'double_verify'
          this.insGetterSetter.minVideoTtd = null
          this.insGetterSetter.minVizTtd = null
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
